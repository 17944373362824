<template>
  <div class="carburant-update-page page-layout">
    <PageHeader
      title="Modifier taxe sur vehicule société (carburant)"
      :has-back="true"
      @back="goBack"
    />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <!--année max-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('annee_min') }">
              <label>Année min *</label>
              <md-input
                v-model="form.annee_min"
                v-validate="'required|numeric'"
                name="annee_min"
                type="annee_min"
              />
              <span class="md-error" v-show="errors.has('annee_min')">{{
                errors.first("annee_min")
              }}</span>
            </md-field>
          </div>

          <!--annee min-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('annee_max') }">
              <label>Année max *</label>
              <md-input
                v-model="form.annee_max"
                v-validate="'numeric'"
                name="annee_max"
                type="annee_max"
              />
              <span class="md-error" v-show="errors.has('annee_max')">{{
                errors.first("annee_max")
              }}</span>
            </md-field>
          </div>

          <!--type-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('type') }">
              <label>Type *</label>
              <md-select v-model="form.type">
                <md-option value="essence">Essence</md-option>
                <md-option value="diesel">Diesel</md-option>
              </md-select>
              <span class="md-error" v-show="errors.has('type')">{{
                errors.first("type")
              }}</span>
            </md-field>
          </div>

          <!--montant-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('montant') }">
              <label>Montant *</label>
              <md-input
                v-model="form.montant"
                v-validate="'required|decimal'"
                name="montant"
                type="text"
              />
              <span class="md-error" v-show="errors.has('montant')">{{
                errors.first("montant")
              }}</span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="updateCarburant"
            >
              Valider
            </md-button>

            <md-button
              class="md-raised md-primary"
              @click.native="openConfirmDeleteModal"
            >
              Supprimer cette taxe
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="removeCarburant"
    />
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import confirmActionModal from "../../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        annee_min: "",
        annee_max: "",
        type: "",
        montant: "",
      },
      isConfirmDeleteModalOpen: false,
      confirmDeleteModalText: {
        header: "Suppression d'une taxe",
        body() {
          return "Vous êtes sur le point de supprimer la taxe sur vehicule de société";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    calculatorsModel
      .getCarburant(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("Carburant with id" + to.params.id + ":");
            console.log(vm.form);
          }
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },

  methods: {
    goBack() {
      window.history.back();
    },
    updateCarburant() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .updateCarburant(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message:
                  "la taxe sur les véhicules de société a bien été modifié",
              });
              this.$router.push(
                "/admin/calculators/taxe-vehicule-societe-carburant/list"
              );
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    removeCarburant() {
      calculatorsModel
        .removeCarburant(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "la taxe sur les véhicules de société a suprimé",
          });
          this.$router.push(
            "/admin/calculators/taxe-vehicule-societe-carburant/list"
          );
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.carburant-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
